import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '../firebase'


Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/*webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/',
    name: 'Dashboard',
    component: () => import(/*webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: { 
      requiresAuth: true,
  }
  },
  {
    path: '/eventlist',
    name: 'EventList',
    component: () => import(/*webpackChunkName: "eventlist" */ '../views/EventList.vue'),
    meta: { 
      requiresAuth: true,
  }
  },
  {
    path: '/installation',
    name: 'Installation',
    component: () => import(/*webpackChunkName: "installation" */ '../views/Installation.vue'),
    meta: { 
      requiresAuth: true,
  }
  },
  {
    path: '/cars',
    name: 'Cars',
    component: () => import(/*webpackChunkName: "cars" */ '../views/Cars.vue'),
    meta: { 
      requiresAuth: true,
  }
  },
  {
    path: '/contractors',
    name: 'Contractors',
    component: () => import(/*webpackChunkName: "contractors" */ '../views/Contractors.vue'),
    meta: { 
      requiresAuth: true,
  }
  },
  {
    path: '/offers',
    name: 'Offers',
    component: () => import(/*webpackChunkName: "offers" */ '../views/Offers.vue'),
    meta: { 
      requiresAuth: true,
  }
  },
  {
    path: '/company',
    name: 'Company',
    component: () => import(/*webpackChunkName: "company" */ '../views/Company.vue'),
    meta: { 
      requiresAuth: true,
  }
  },
  {
    path: '/customer',
    name: 'Customer',
    component: () => import(/*webpackChunkName: "customer" */ '../views/Customer.vue'),
    meta: { 
      requiresAuth: true,
  }
  },
  {
    path: '/addcontractor',
    name: 'AddContractor',
    component: () => import(/*webpackChunkName: "addcontractor" */ '../views/AddContractor.vue'),
    meta: { 
      requiresAuth: true,
  }
  },
  {
    path: '/addcompany',
    name: 'AddCompany',
    component: () => import(/*webpackChunkName: "addcompany" */ '../views/AddCompany.vue'),
    meta: { 
      requiresAuth: true,
  }
  },
  {
    path: '/addcustomer',
    name: 'AddCustomer',
    component: () => import(/*webpackChunkName: "addcustomer" */ '../views/AddCustomer.vue'),
    meta: { 
      requiresAuth: true,
  }
  },
  {
    path: '/addinstallation',
    name: 'AddInstallation',
    component: () => import(/*webpackChunkName: "addinstallation" */ '../views/AddInstallation.vue'),
    meta: { 
      requiresAuth: true,
  }
  },
  {
    path: '/usersettings',
    name: 'UserSettings',
    component: () => import(/*webpackChunkName: "usersettings" */ '../views/UserSettings.vue'),
    meta: { 
      requiresAuth: true,
  }
},
  {
    path: '/addsquad',
    name: 'AddSquad',
    component: () => import(/*webpackChunkName: "addsquad" */ '../views/AddSquad.vue'),
    meta: { 
      requiresAuth: true,
  }
},
{
  path: '/squad',
  name: 'Squad',
  component: () => import(/*webpackChunkName: "squad" */ '../views/Squad.vue'),
  meta: { 
    requiresAuth: true,
}
},
{
  path: '/addemployee',
  name: 'AddEmployee',
  component: () => import(/*webpackChunkName: "addemployee" */ '../views/AddEmployee.vue'),
  meta: { 
    requiresAuth: true,
}
},
{
  path: '/services',
  name: 'Services',
  component: () => import(/*webpackChunkName: "services" */ '../views/Services.vue'),
  meta: { 
    requiresAuth: true,
}
},
{
  path: '/payments',
  name: 'Payments',
  component: () => import(/*webpackChunkName: "payments" */ '../views/Payments.vue'),
  meta: { 
    requiresAuth: true,
}
},
{
  path: '/addpayment',
  name: 'AddPayment',
  component: () => import(/*webpackChunkName: "addpayment" */ '../views/AddPayment.vue'),
  meta: { 
    requiresAuth: true,
}
},
{
  path: '/payments-costs',
  name: 'PaymentsCosts',
  component: () => import(/*webpackChunkName: "paymentscosts" */ '../views/PaymentsCosts.vue'),
  meta: { 
    requiresAuth: true,
}
},
{
  path: '/payments-due',
  name: 'PaymentsDue',
  component: () => import(/*webpackChunkName: "paymentsdue" */ '../views/PaymentsDue.vue'),
  meta: { 
    requiresAuth: true,
}
},
{
  path: '/payments-archive',
  name: 'PaymentsArchive',
  component: () => import(/*webpackChunkName: "paymentsarchive" */ '../views/PaymentsArchive.vue'),
  meta: { 
    requiresAuth: true,
}
},
{
  path: '/magazine',
  name: 'Magazine',
  component: () => import(/*webpackChunkName: "magazine" */ '../views/Magazine.vue'),
  meta: { 
    requiresAuth: true,
}
},
{
  path: '/add-magazine-item',
  name: 'Add Magazine Item',
  component: () => import(/*webpackChunkName: "addmagazineitem" */ '../views/AddMagazineItem.vue'),
  meta: { 
    requiresAuth: true,
}
},
{
  path: '/offers-archive',
  name: 'OffersArchive',
  component: () => import(/*webpackChunkName: "offersarchive" */ '../views/OffersArchive.vue'),
  meta: { 
    requiresAuth: true,
}},
{
  path: '/meetings-archive',
  name: 'MeetingsArchive',
  component: () => import(/*webpackChunkName: "offersarchive" */ '../views/MeetingsArchive.vue'),
  meta: { 
    requiresAuth: true,
}
},
{
  path: '/add-meeting',
  name: 'AddMeeting',
  component: () => import(/*webpackChunkName: "offersarchive" */ '../views/AddMeeting.vue'),
  meta: { 
    requiresAuth: true,
}
}

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next)=> {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router
