import Vue from 'vue'
import Vuex from 'vuex'
import * as firebase from '../firebase'
import router from '../router/index'


Vue.use(Vuex)

// firebase.contractorsCollection.onSnapshot(snapshot => {
//   let contractorsArray = []
//   snapshot.forEach(doc => {
//     let contractor = doc.data()
//     contractor.id = doc.id
//     contractorsArray.push(contractor)
//   })
//   store.commit('setContractors', contractorsArray)
// })

// firebase.installationsCollection.onSnapshot(snapshot => {
//   let installationsArray = []
//   snapshot.forEach(doc => {
//     let installation = doc.data()
//     installation.id = doc.id
//     installationsArray.push(installation)
//   })
//   store.commit('setInstallations', installationsArray)
// })

// firebase.paymentsCollection.onSnapshot(snapshot => {
//   let paymentsArray = []
//   snapshot.forEach(doc => {
//     let payment = doc.data()
//     payment.id = doc.id
//     if(payment.status === "new"){
//     paymentsArray.push(payment)
//   }
//   })
//   store.commit('setPayments', paymentsArray)
// })

// firebase.paymentsCollection.onSnapshot(snapshot => {
//   let paymentsArray = []
//   snapshot.forEach(doc => {
//     let payment = doc.data()
//     payment.id = doc.id
//     if(payment.status === "end"){
//     paymentsArray.push(payment)
//   }
//   })
//   store.commit('setPaymentsArchive', paymentsArray)
// })


// firebase.magazineCollection.onSnapshot(snapshot => {
//   let magazineArray = []
//   snapshot.forEach(doc => {
//     let magazineItem = doc.data()
//     magazineItem.id = doc.id
   
//     magazineArray.push(magazineItem)
  
//   })
//   store.commit('setMagazine', magazineArray)
// })


// firebase.offersCollection.onSnapshot(snapshot => {
//   let offersArray = []
//   snapshot.forEach(doc => {
//     let offersItem = doc.data()
//     offersItem.id = doc.id
   
//     offersArray.push(offersItem)
  
//   })
//   store.commit('setOffers', offersArray)
// })

firebase.othersCollection.onSnapshot(snapshot => {
  let array = []
  snapshot.forEach(doc => {
    let data = doc.data()
    data.id = doc.id
    // console.log(data.number)
    array.push(data)
  })
  store.commit('setOthers', array)
})

// -----------------------------

// firebase.carsCollection.onSnapshot(snapshot => {
//   let carsArray = []
//   snapshot.forEach(doc => {
//     let car = doc.data()
//     car.id = doc.id
//     carsArray.push(car)
//   })
//   store.commit('setCars', carsArray)
// })

// firebase.squadsCollection.onSnapshot(snapshot => {
//   let squadsArray = []
//   snapshot.forEach(doc => {
//     let squad = doc.data()
//     squad.id = doc.id
//     squadsArray.push(squad)
//   })
//   store.commit('setSquads', squadsArray)
// })

// firebase.employeesCollection.onSnapshot(snapshot => {
//   let employeesArray = []
//   snapshot.forEach(doc => {
//     let employee = doc.data()
//     employee.id = doc.id
//     employeesArray.push(employee)
//   })
//   store.commit('setEmployees', employeesArray)
// })
const store = new Vuex.Store({
  state: {
    userProfile: {},
    contractors: [],
    installations: [],
    payments:[],
    magazine: [],
    paymentsArchive:[],
    cars: [],
    squads: [],
    employees: [],
    offers:[],
    others:[],
    RotensoInUnits: [
      {
        id:1,
        name: 'Mirai',
        power: [2.6,3.5]
      },
      {id:2,
        name:'Teta',
        power: [2.6,3.5,5.1,6.9]
      }
    
    ],
    RotensoOutUnits: [
      {id:1,
        name:'Hiro S',
        power:[4.1,5.3,6.2,7.9,8.2,10.5,12.3]
      },
      {id:2,
        name:'Hiro N',
        power:[4.1,5.1,7.5,9.4,11.8]
      }
    ],
    offersElements: [

      {
        id:1,
        name: 'Klimatyzator',
        measure: 'szt',
        amount: 1,
        price: 0
      },
      {
        id:2,
        name: 'Instalacja miedziana',
        measure: 'mb',
        amount: 1,
        price: 29.50
      }
      ,
      {
        id:3,
        name: 'Instalacja sterująca',
        measure: 'mb',
        amount: 1,
        price: 6.50
      },
      {
        id:4,
        name: 'Elementy montażowe',
        measure: 'szt',
        amount: 1,
        price: 150
      },
      {
        id:5,
        name: 'Wspornik skraplacza',
        measure: 'szt',
        amount: 1,
        price: 75
      },
      {
        id:6,
        name: 'Dostawa, montaż oraz uruchomienie',
        measure: 'szt',
        amount: 1,
        price: 650
      }


    ],
    meetingsElements: [

      {
        id:1,
        name: 'Klimatyzator',
        measure: 'szt',
        amount: 1,
        price: 1
      },
      {
        id:2,
        name: 'Instalacja miedziana',
        measure: 'mb',
        amount: 1,
        price: 29.50
      }
      ,
      {
        id:3,
        name: 'Instalacja sterująca',
        measure: 'mb',
        amount: 1,
        price: 6.50
      },
      {
        id:4,
        name: 'Elementy montażowe',
        measure: 'szt',
        amount: 1,
        price: 150
      },
      {
        id:5,
        name: 'Wspornik skraplacza',
        measure: 'szt',
        amount: 1,
        price: 75
      },
      {
        id:6,
        name: 'Dostawa, montaż oraz uruchomienie',
        measure: 'szt',
        amount: 1,
        price: 650
      }


    ],
    console: "www.2qbs.pl"
  },
  mutations: {
    setUserProfile(state, val) {
      state.userProfile = val
    },
    setContractors(state, val) {
      state.contractors = val
    },
    setInstallations(state, val) {
      state.installations = val
    },
    setPayments(state, val) {
      state.payments = val
    },
    setMagazine(state, val) {
      state.magazine = val
    },
    setPaymentsArchive(state, val) {
      state.paymentsArchive = val
    },
    setCars(state, val) {
      state.cars = val
    },
    setSquads(state, val) {
      state.squads = val
    },
    setEmployees(state, val) {
      state.employees = val
    },
    setOffers(state,val){
      state.offers = val
    },
    setMeetings(state,val){
      state.meetings = val
    },
    setOthers(state,val){
    
      state.others = val;
    },
    updateInstallations(state, val) {
      state.installations = val
    },
    
  },
  actions: {
    async login({ dispatch }, form) {
      //logowanko
      const { user } = await firebase.auth.signInWithEmailAndPassword(form.email, form.password)

      //akcja przypięcia uzytkownika i ustawnienie stanu
      dispatch('fetchUserProfile', user)
    },
    async fetchUserProfile({ commit }, user) {
      //przypiecie uzytkownika
      const userProfile = await firebase.usersCollection.doc(user.uid).get()
      commit('setUserProfile', userProfile.data())
      //ustawienie profilu uzytkownika w stanie 
      // console.log(userProfile)
      //zmiana drogi na daszbord.
      if (router.currentRoute.path === '/login') {
        router.push('/')
      }
    },

    async updateProfile({ dispatch }, user) {
      const userId = firebase.auth.currentUser.uid
      //uaktualnienie obiektu uytkownika 
      await firebase.usersCollection.doc(userId).set({
        name: user.name,
      })


      dispatch('fetchUserProfile', { uid: userId })
    },

    async logout({ commit }) {
      await firebase.auth.signOut()
      //zmiana stanu uzytkownika mutacja na pusta tablica
      commit('setUserProfile', {})
      //przekierownaie do ponownego logowania
      router.push('/login')
    },

    //createDATABASE ELEMENTS 

    async createContractor({ state }, data) {
      await firebase.contractorsCollection.add({
        createOn: new Date(),
        name: data.name,
        label: data.name,
        nip: data.nip,
        contactPerson: data.contactPerson,
        email: data.email,
        phoneNumber: data.phoneNumber,
        address: data.address,
        notice: data.notice,
        type: data.type,
        user: state.userProfile

      })

    },
    async createCustomer({ state }, data) {
      await firebase.contractorsCollection.add({
        createOn: new Date(),
        name: data.name,
        label: data.name,
        email: data.email,
        nip: "-",
        phoneNumber: data.phoneNumber,
        address: data.address,
        notice: data.notice,
        type: data.type,
        user: state.userProfile
        // user: state.userProfile

      })

    },
    async createInstallation({ state }, data) {
      await firebase.installationsCollection.add({
        createOn: new Date(),
        contractor: data.contractor,
        serialNumber: data.serialNumber,
        // amountOfRefrigerant: data.amountOfRefrigerant,
        gallery: [],
        date: data.date,
        notice: data.notice,
        overview: data.overview,
        user: state.userProfile,
        service: data.service,
        squad: data.squad
      })


    },
    async createPayment({ state }, data) {
      await firebase.paymentsCollection.add({
        createOn: new Date(),
        contractor: data.contractor,
        invoiceNumber: data.invoiceNumber,
        user:state.userProfile,
        date: data.date,
        notice: data.notice,
        price: data.price,
        status: "new",
        type: data.type
      })


    },
    async createMagazineItem({ state }, data) {
      console.log('createMagazineItem')
      await firebase.magazineCollection.add({
        createOn: new Date(),
        title: data.title,
        quantity: data.quantity,
        user:state.userProfile,
        notice: data.notice,
        lastEdit: new Date()
      })


    },
    async updateCounter({state},data) {

      console.log(state.log)

        await firebase.othersCollection.doc("counter").update(
              {
                value: data+1
              })
    
    },

    async createOffer({ state }, data) {
      await firebase.offersCollection.add({
        createOn: new Date(),
        
        date: data.date,
        offer: data.offer,
        vat: data.vat,
        netto: data.netto,
        brutto: data.brutto,
        imageUrl: data.imageUrl,
        header: data.header,
        notice: data.notice,
        user: state.userProfile,
        header2: data.header2,
        subheader: data.subheader,
        contractor: data.contractor,
        offerNumber:data.offerNumber,
        meeting: false
      })
    

    },

    
    async createMeeting({ state }, data) {
      console.log(state.userProfile)
      await firebase.offersCollection.add({
        createOn: new Date(),
       
        date: data.date,
        vat: data.vat,
        notice: data.notice,
        user: state.userProfile,
        contractor: data.contractor,
        offer: data.offer,
        brutto: data.brutto,
        netto: data.netto,
        imageUrl: data.imageUrl,
        offerNumber: data.offerNumber,
        contractorAddress: data.contractorAddress,
        contractorNumber: data.contractorNumber,
        contractorName: data.contractorName,
        header: data.offerHeader,
        header2: data.header,
        subheader: data.subheader,
        meeting:true

   
      })
    

    },

    //DELETE OPTIONS 

    async deleteInstallation({ state }, id) {
     
      await firebase.installationsCollection.doc(id.id).delete()
      console.log(state.console)
    },
    async deletepayment({state},id){
      await firebase.paymentsCollection.doc(id.id).delete()
      console.log(state.console)

    },
    async deleteMagazineItem({state},id){
      await firebase.magazineCollection.doc(id.id).delete()
      console.log(state.console)

    },

    async deleteOffer({state},id){
      await firebase.offersCollection.doc(id.id).delete()
      console.log(state.console)

    },
    async deleteMeeting({state},id){
      await firebase.meetingsCollection.doc(id.id).delete()
      console.log(state.console)

    },

    //Update

    async updateInstallation({ state }, data) {
      let docRef = await firebase.installationsCollection.doc(data.id).get()
      let docData = docRef.data()
      let ifGallery = docData.gallery
      let ifBinder = docData.binder
      // console.log(ifBinder.length)
      if(ifBinder === 'undefined') {
        console.log('binderundefined')
        await firebase.installationsCollection.doc(data.id).set({ binder:"" }, { merge: true })
      }

      if(!ifGallery) {
        await firebase.installationsCollection.doc(data.id).set({ gallery: [] }, { merge: true })
      }
      // else if (!ifBinder) {
      //  await firebase.installationsCollection.doc(data.id).update(
      //         {
      //           binder:data.binder
      //         })
            
        
      // }

        await firebase.installationsCollection.doc(data.id).update(
          {
            service: data.service,
            notice: data.notice,
            squad: data.squad,
            gallery: data.gallery,
            binder: data.binder
          })
      


      // if(ifGallery && ifBinder ){
      //   await firebase.installationsCollection.doc(data.id).update(
      //         {
      //           service: data.service,
      //           notice: data.notice,
      //           squad: data.squad,
      //           gallery: data.gallery,
      //           binder: data.binder
      //         })
      // } else {


      //        await firebase.installationsCollection.doc(data.id).set({ gallery: [] }, { merge: true }).then(()=>{
      //   firebase.installationsCollection.doc(data.id).update(
      //     {
      //       service: data.service,
      //       notice: data.notice,
      //       squad: data.squad
      //     })
      // })
      // }
      
      console.log(state.console)
  

    
    },
    async updatePayment({state},data) {
      console.log(data)
        await firebase.paymentsCollection.doc(data.id).update(
              {
                date: data.date,
                notice: data.notice,
                price: data.price,
                type: data.type,
                lastEdit: new Date(),
          
              })
      
      console.log(state)
    
  

    
    },
    async updateMagazineItem({state},data) {
      console.log(data)
        await firebase.magazineCollection.doc(data.id).update(
              {
                title: data.title,
                notice: data.notice,
                quantity: data.quantity,
                lastEdit: new Date(),
          
              })
      
      console.log(state)
    
  

    
    },
    async endPayment({state},data) {
     
        await firebase.paymentsCollection.doc(data.id).update(
              {
                status: data.status,
        
              })
      
      console.log(state)
    
  

    
    },

    async updateOffer({state},data){
      await firebase.offersCollection.doc(data.id).update(
      {
      
        offer: data.offer,
        vat: data.vat,
        netto: data.netto,
        brutto: data.brutto,
        imageUrl: data.imageUrl,
        header: data.header,
        notice: data.notice,
        header2: data.header2,
        subheader: data.subheader,
        contractor: data.contractor,
        
      })
      console.log(data)
      console.log(state)
    },

    async updateMeeting({state},data){
      await firebase.meetingsCollection.doc(data.id).update(
      {
      
        offer: data.offer,
        vat: data.vat,
        netto: data.netto,
        brutto: data.brutto,
        imageUrl: data.imageUrl,
        meetingsImageUrl: data.meetingsImageUrl,
        header: data.header,
        notice: data.notice,
        header2: data.header2,
        subheader: data.subheader,
        contractor: data.contractor,
        
      })
      console.log(data)
      console.log(state)
    },
    // async updateMagazineItem({state},data) {
      
    //     await firebase.magazineCollection.doc(data.id).update(
    //           {
    //             title: data.tile,
    //             notice: data.notice,
    //             quantity: data.quantity,
    //             type: data.type
          
    //           })
      
    //   console.log(state)
    
  

    
    // },
    
    async updateCompany({ state }, data) {
      console.log({ state })
      console.log(data)

      await firebase.contractorsCollection.doc(data.id).update(
        {
          address: data.address,
          notice: data.notice,
          email: data.email,
          contactPerson: data.contactPerson,
          name: data.name,
          label: data.name + " " + data.address,
          nip: data.nip,
          phoneNumber: data.phoneNumber,

        }
      )
    },
    async updateCustomer({ state }, data) {
      console.log({ state })
      console.log(data)

      await firebase.contractorsCollection.doc(data.id).update(
        {
          address: data.address,
          notice: data.notice,
          email: data.email,
          label: data.name + " " + data.address,
          name: data.name,
          phoneNumber: data.phoneNumber,

        }
      )
    },

    async getInstallations(){
      await  firebase.installationsCollection.onSnapshot(snapshot => {
          let installationsArray = []
          snapshot.forEach(doc => {
            let installation = doc.data()
            installation.id = doc.id
            installationsArray.push(installation)
          })
          store.commit('setInstallations', installationsArray)
        })
    },

    async getContractors(){
      await firebase.contractorsCollection.onSnapshot(snapshot => {
          let contractorsArray = []
          snapshot.forEach(doc => {
            let contractor = doc.data()
            contractor.id = doc.id
            contractorsArray.push(contractor)
          })
          store.commit('setContractors', contractorsArray)
        })
    },

    async getOffers({state},data){
      console.log(state)
      var start = new Date(data.startDate);
      console.log(start)
      if(start == "undefined" || start == "Invalid Date"){
        start = new Date("2020-01-1")
        // console.log("startDateIf=",start)
      }
      var end = new Date(data.endDate);
      end.setDate(end.getDate() + 1);
      console.log(end)
      if(end == 'undefined' || end == "Invalid Date"){
        end = new Date()
        // console.log("endDateIf=",end)
      }
      await firebase.offersCollection.where('date','>=',start).where('date','<=',end).orderBy('date','desc').limit(data.lastOffers).onSnapshot(snapshot => {
          let offersArray = []
          snapshot.forEach(doc => {
       
            let offersItem = doc.data()
            offersItem.id = doc.id
           
            offersArray.push(offersItem)
          
          })
          store.commit('setOffers', offersArray)
        })
    },
    async getMeetings({state},data){
      console.log(state)
      console.log(data)
      // var start = new Date(data.startDate);
      // console.log(start)
      // if(start == "undefined" || start == "Invalid Date"){
      //   start = new Date("2020-01-1")
      //   // console.log("startDateIf=",start)
      // }
      // var end = new Date(data.endDate);
      // if(end == 'undefined' || end == "Invalid Date"){
      //   end = new Date()
      //   // console.log("endDateIf=",end)
      // }orderBy('date','desc').limit(data.lastOffers)
      await firebase.offersCollection.where('meeting','==',true).onSnapshot(snapshot => {
          let offersArray = []
          snapshot.forEach(doc => {
       
            let offersItem = doc.data()
            offersItem.id = doc.id
           
            offersArray.push(offersItem)
          
          })
          store.commit('setMeetings', offersArray)
        })
    },
    async getPayments(){
      await firebase.paymentsCollection.onSnapshot(snapshot => {
          let paymentsArray = []
          snapshot.forEach(doc => {
            let payment = doc.data()
            payment.id = doc.id
            if(payment.status === "new"){
            paymentsArray.push(payment)
          }
          })
          store.commit('setPayments', paymentsArray)
        })
    },
    async getPaymentsArchive(){
      await firebase.paymentsCollection.onSnapshot(snapshot => {
          let paymentsArray = []
          snapshot.forEach(doc => {
            let payment = doc.data()
            payment.id = doc.id
            if(payment.status === "end"){
            paymentsArray.push(payment)
          }
          })
          store.commit('setPaymentsArchive', paymentsArray)
        })
      },

    async getMagazine() {
      await firebase.magazineCollection.onSnapshot(snapshot => {
          let magazineArray = []
          snapshot.forEach(doc => {
            let magazineItem = doc.data()
            magazineItem.id = doc.id
           
            magazineArray.push(magazineItem)
          
          })
          store.commit('setMagazine', magazineArray)
        })
    },

  },
  modules: {
  }
})

export default store